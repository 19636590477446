import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import GeneralSelectMenu from "../buttons/GeneralSelectMenu";
import GeneralControlButton from "../buttons/GeneralControlButton";
import GeneralRadioGroup from "../buttons/GeneralRadioGroup";
import GeneralCheckbox from "../buttons/GeneralCheckbox";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";
import PaymentPopOver from "./../popOver/PaymentPopOver";
import InvoiceTable from "../../components/tables/InvoiceTable";
import Button from "@mui/material/Button";
import { getCustomerReservations } from "../../api/DockMasterAPI";
import { getAuthCookies } from "../../context/CookieManager";
import { getLocationsAPI } from "../../api/DockMasterAPI";
const TerminalFormDialogElement = ({
  tenantData,
  prefetchedOptions,
  serviceType,
  elements,
  onClose,
  onSubmit,
  buttonText,
  customerInfo,
  disabledSave = false,
  disabledPopOver = false,
  sendRequest,
  PaymentRequestUUID,
  DefaultPayLoc,
}) => {
  const [formValues, setFormValues] = useState({});
  const [options, setOptions] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(DefaultPayLoc);
  const [reservationData, setReservationData] = useState({});
  const [locations, setLocations] = useState([]);

  const fetchReservation = async (reservationId) => {
    try {
      const user_id = getAuthCookies("tenantId");
      const jsonResponse = await getCustomerReservations(
        user_id,
        reservationId,
      );
      setReservationData(jsonResponse.data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const depositTypeFields = {
    all: [
      // All Invoices option
      { type: "table", name: "allInvoices", label: "allInvoices" },
    ],
    BS: [
      // Boat Sale Deposit
      { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
      {
        type: "select",
        name: "customerContracts",
        label: "Customer Contracts",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    SO: [
      // Special order Deposit
      { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
      {
        type: "select",
        name: "customerSpecialOrders",
        label: "Customer Special Orders",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    QU: [
      // Quote Sale Deposit
      { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
      {
        type: "select",
        name: "customerQuotes",
        label: "Customer Quotes",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    KD: [
      // Key Deposit
      {
        type: "depositInformation",
        name: "depositInformation",
        label: "Deposit Information",
        elements: [
          { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
          { type: "text", name: "year", label: "Year" },
          { type: "text", name: "agreementNum", label: "Agreement Number" },
        ],
      },
      {
        type: "select",
        name: "customerBoats",
        label: "Customer Boats",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    BD: [
      // Security Deposit
      {
        type: "depositInformation",
        name: "depositInformation",
        label: "Deposit Information",
        elements: [
          { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
          { type: "text", name: "year", label: "Year" },
          { type: "text", name: "agreementNum", label: "Agreement Number" },
        ],
      },
      {
        type: "select",
        name: "customerBoats",
        label: "Customer Boats",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    DS: [
      // Drystack Deposit
      {
        type: "depositInformation",
        name: "depositInformation",
        label: "Deposit Information",
        elements: [
          { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
          { type: "text", name: "year", label: "Year" },
          { type: "text", name: "agreementNum", label: "Agreement Number" },
        ],
      },
      {
        type: "select",
        name: "customerBoats",
        label: "Customer Boats",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    SL: [
      // Slip Deposit
      {
        type: "depositInformation",
        name: "depositInformation",
        label: "Deposit Information",
        elements: [
          { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
          { type: "text", name: "year", label: "Year" },
          { type: "text", name: "agreementNum", label: "Agreement Number" },
        ],
      },
      {
        type: "select",
        name: "customerBoats",
        label: "Customer Boats",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    SS: [
      // Summer Storage Deposit
      {
        type: "depositInformation",
        name: "depositInformation",
        label: "Deposit Information",
        elements: [
          { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
          { type: "text", name: "year", label: "Year" },
          { type: "text", name: "agreementNum", label: "Agreement Number" },
        ],
      },
      {
        type: "select",
        name: "customerBoats",
        label: "Customer Boats",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    TR: [
      // Trailer Storage Deposit
      {
        type: "depositInformation",
        name: "depositInformation",
        label: "Deposit Information",
        elements: [
          { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
          { type: "text", name: "year", label: "Year" },
          { type: "text", name: "agreementNum", label: "Agreement Number" },
        ],
      },
      {
        type: "select",
        name: "customerBoats",
        label: "Customer Boats",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    WS: [
      // Winter Storage Deposit
      {
        type: "depositInformation",
        name: "depositInformation",
        label: "Deposit Information",
        elements: [
          { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
          { type: "text", name: "year", label: "Year" },
          { type: "text", name: "agreementNum", label: "Agreement Number" },
        ],
      },
      {
        type: "select",
        name: "customerBoats",
        label: "Customer Boats",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    RD: [
      // Reservation Deposit
      // TODO: add the necessary fields
      { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
      { type: "text", name: "reservationId", label: "Reservation #" },
      {
        type: "button",
        name: "reservationCheck",
        label: "Check",
        handler: fetchReservation,
      },
      { type: "div", label: "Reservation Detail" },
    ],
    WD: [
      // Wait List Deposit
      // TODO: add the necessary fields
      { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
      {
        type: "select",
        name: "customerWaitList",
        label: "Wait List Number",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    WI: [
      // Work Order Invoice
      // Select Invoices
      {
        type: "select",
        name: "customerInvoices",
        label: "Customer Invoices",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    WO: [
      // Work Order Deposit
      { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
      {
        type: "select",
        name: "customerWorkOrders",
        label: "Customer Work Orders",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    SI: [
      // Specific Invoice
      {
        type: "select",
        name: "specificInvoice",
        label: "Customer Invoices",
        includeAllOption: false,
        fullWidth: true,
      },
    ],
    MD: [
      // Miscellaneous Deposit
      { type: "text", name: "depositAmount", label: "Deposit Amount", required: true  },
    ],
  };

  const checkFormValidity = () => {
    const allElements = [...elements, ...additionalFields];
    return allElements.every((element) => {
      if (!element.required) return true;
      const value = formValues[element.name];
      return value !== undefined && value !== "";
    });
  };

  useEffect(() => {
    const isValid = checkFormValidity();
    setIsFormValid(isValid);
  }, [formValues, elements, additionalFields]);

  const getLocations = async (tenantId) => {
    try {
      const jsonResponse = await getLocationsAPI(tenantId);
      setLocations(jsonResponse ?? []);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (tenantData.ClientID) getLocations(tenantData.ClientID);
  }, [tenantData.ClientID]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (prefetchedOptions) {
          setOptions(prefetchedOptions);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error("Failed to fetch options:", error);
      }
    };

    fetchData();

    const initialFormValues = elements.reduce(
      (acc, element) => ({
        ...acc,
        [element.name]: element.defaultValue || element.value || "",
      }),
      {},
    );

    setFormValues({
      ...initialFormValues,
      location: DefaultPayLoc || initialFormValues.location || "",
    });
  }, [serviceType, elements, DefaultPayLoc]);

  useEffect(() => {
    if (formValues.depositType) {
      const { location, depositType, allInvoices } = formValues;
      setFormValues({
        location: location,
        depositType: depositType,
        allInvoices: allInvoices,
      });
      const newFields = depositTypeFields[formValues.depositType] || [];
      setAdditionalFields(newFields);
    } else {
      setAdditionalFields([]);
    }
  }, [formValues.depositType]);

  useEffect(() => {
    if (formValues.depositType === "all") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allInvoices: options.customerInvoices,
      }));
    }
  }, [formValues.depositType, options.customerInvoices]);
  useEffect(() => {
    if (selectedLocation === "all") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allInvoices: options.allInvoices,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        allInvoices: options?.allInvoices?.filter(
          (item) => item.locationCode === selectedLocation,
        ),
      }));
    }
  }, [selectedLocation]);
  const handleChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === "location") {
      setSelectedLocation(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!checkFormValidity()) {
      console.error("Please fill out all required fields.");
      return;
    }
    onSubmit({ ...formValues, customer: customerInfo });
  };

  const renderElement = (element, index) => {
    let elementOptions = [];
    if (element.type === "select") {
      if (element.name === "location") {
        elementOptions = options.locations || [];
        return (
          <Box sx={{ my: 1 }}>
            <GeneralSelectMenu
              key={index}
              defaultValue={selectedLocation}
              name={element.name}
              label={element.label}
              options={elementOptions}
              includeAllOption={true}
              onChange={(value) => handleChange(element.name, value)}
              required={element.required}
            />
          </Box>
        );
      } else if (element.name === "depositType") {
        elementOptions = options.depositTypes || [];
      } else if (element.name === "customerContracts") {
        if (options.customerContracts) {
          if (selectedLocation != "all") {
            elementOptions = options.customerContracts.filter(
              (item) => item.locationCode === selectedLocation,
            );
          } else {
            elementOptions = options.customerContracts;
          }
        }
      } else if (element.name === "customerQuotes") {
        if (options.customerQuotes) {
          if (selectedLocation != "all") {
            elementOptions = options.customerQuotes.filter(
              (item) => item.locationCode === selectedLocation,
            );
          } else {
            elementOptions = options.customerQuotes;
          }
        }
      } else if (element.name === "customerBoats") {
        elementOptions = options.customerBoats || [];
      } else if (element.name === "customerWaitList") {
        elementOptions = options.customerWaitList || [];
      } else if (element.name === "customerReservations") {
        if (options.customerReservations) {
          if (selectedLocation != "all") {
            elementOptions = options.customerReservations.filter(
              (item) => item.locationCode === selectedLocation,
            );
          } else {
            elementOptions = options.customerReservations;
          }
        }
      } else if (element.name === "customerInvoices") {
        if (options.customerInvoices) {
          if (selectedLocation != "all") {
            elementOptions = options.customerInvoices.filter(
              (item) => item.locationCode === selectedLocation,
            );
          } else {
            elementOptions = options.customerInvoices;
          }
        }
      } else if (element.name === "customerSpecialOrders") {
        if (options.customerSpecialOrders) {
          if (selectedLocation != "all") {
            elementOptions = options.customerSpecialOrders.filter(
              (item) => item.locationCode === selectedLocation,
            );
          } else {
            elementOptions = options.customerSpecialOrders;
          }
        }
      } else if (element.name === "specificInvoice") {
        if (options.specificInvoice) {
          if (selectedLocation != "all") {
            elementOptions = options.specificInvoice.filter(
              (item) => item.locationCode === selectedLocation,
            );
          } else {
            elementOptions = options.specificInvoice;
          }
        }
      } else if (element.name === "customerWorkOrders") {
        if (options.customerWorkOrders) {
          if (selectedLocation != "all") {
            elementOptions = options.customerWorkOrders.filter(
              (item) => item.locationCode === selectedLocation,
            );
          } else {
            elementOptions = options.customerWorkOrders;
          }
        }
      }
    } else if (element.type === "table") {
      if (options.allInvoices) {
        if (selectedLocation != "all") {
          elementOptions = options.allInvoices.filter(
            (item) => item.locationCode === selectedLocation,
          );
        } else {
          elementOptions = options.allInvoices;
        }
      }
    } else {
      elementOptions = options;
    }

    if (element.type === "select" && elementOptions.length === 0) {
      return (
        <TextField
          key={index}
          disabled
          fullWidth={false}
          label={element.label}
          defaultValue={"No data available"}
          helperText={`No options available for ${element.label}`}
          type="text"
          variant="outlined"
        />
      );
    } else if (element.type === "select") {
      return (
        <Box sx={{ my: 1 }}>
          <GeneralSelectMenu
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            options={elementOptions}
            includeAllOption={element.includeAllOption}
            required={element.required ? element.required : false}
            onChange={(value) => handleChange(element.name, value)}
            {...element.props}
          />
        </Box>
      );
    }

    switch (element.type) {
      case "div":
        return (
          <Box sx={{ m: 1, mx: "0!important" }}>
            {element.label}:
            <div>
              {reservationData?.id ? (
                <div>
                  <div>Id:{reservationData.id}</div>
                  <div>
                    Boat:{reservationData.boatId}, {reservationData.boatName}
                  </div>
                  <div>Description:{reservationData.generalComments}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Box>
        );
      case "button":
        return (
          <Button
            sx={{ mx: "0", width: "100%" }}
            variant="outlined"
            onClick={() => element.handler(formValues.reservationId)}
          >
            {element.label}
          </Button>
        );
      case "text":
        return (
          <TextField
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            type="text"
            placeholder={element.placeholder}
            variant="outlined"
            fullWidth={element.fullWidth}
            sx={{ mx: "0!important" }}
            required={element.required ? element.required : false}
            onChange={(e) => handleChange(element.name, e.target.value)}
            {...element.props}
          />
        );
      case "password":
        return (
          <TextField
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            type="password"
            placeholder={element.placeholder}
            variant="outlined"
            fullWidth={element.fullWidth}
            required={element.required ? element.required : false}
            onChange={(e) => handleChange(element.name, e.target.value)}
            {...element.props}
          />
        );
      case "switch":
        return (
          <GeneralControlButton
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            description={element.description}
            required={element.required ? element.required : false}
            onChange={(checked) => handleChange(element.name, checked)}
            {...element.props}
          />
        );
      case "radio":
        return (
          <GeneralRadioGroup
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            options={element.options}
            required={element.required ? element.required : false}
            onChange={(e) => handleChange(element.name, e.target.value)}
            {...element.props}
          />
        );
      case "checkbox":
        return (
          <GeneralCheckbox
            name={element.name}
            label={element.label}
            required={element.required ? element.required : false}
            onChange={(checked) => handleChange(element.name, checked)}
          />
        );
      case "table":
        return <InvoiceTable data={elementOptions} locations={locations} />;
      case "depositInformation":
        return element.elements.map((subElement, subIndex) =>
          renderSubElement(subElement, `${index}-${subIndex}`),
        );
      default:
        return null;
    }
  };

  const renderSubElement = (subElement, key) => {
    switch (subElement.type) {
      case "text":
        return (
          <TextField
            key={key}
            defaultValue={subElement.value}
            name={subElement.name}
            label={subElement.label}
            type="text"
            placeholder={subElement.placeholder}
            variant="outlined"
            fullWidth={subElement.fullWidth}
            required={subElement.required ? subElement.required : false}
            onChange={(e) => handleChange(subElement.name, e.target.value)}
            sx={{ mx: "0!important" }}
            {...subElement.props}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiTextField-root": { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {elements.map((element, index) => renderElement(element, index))}
        {additionalFields.map((element, index) =>
          renderElement(element, index + elements.length),
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mt: 2,
            gap: "10px",
            flexWrap: "nowrap",
          }}
        >
          {onClose && (
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          )}
          <PrimaryButton type="submit" disabled={!isFormValid || disabledSave}>
            {buttonText || "Create"}
          </PrimaryButton>
          <a
            style={{
              display: PaymentRequestUUID ? "block" : "none",
            }}
            href={
              process.env.REACT_APP_FRONT_END_URL +
              "otp?id=" +
              PaymentRequestUUID
            }
          >
            Open Payment Link
          </a>
          {serviceType === "terminal" && (
            <PaymentPopOver
              disabled={disabledPopOver}
              sendRequest={sendRequest}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default TerminalFormDialogElement;
