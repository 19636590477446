import * as React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";

import DMPay from "./services/DMPay";
import PageNotFound from "./pages/PageNotFound";
import Error from "./pages/Error";
import PaymentSuccess from "./pages/PaymentSuccess";
import Otp from "./pages/Otp";

import LoginForm from "./components/Login/LoginForm";
import { useAuth } from "./context/AuthContext";
import Payment from "./components/form/Payment";
import Admin from "./layout/admin";
 
export default function App() {
  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/admin" /> : <LoginForm />}
        />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/dmpay" element={<DMPay />} />
        <Route path="/pay/:customerId/:tenantId" element={<DMPay />} />
        <Route path="/checkout" element={<Payment />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="/error" element={<Error />} />
        <Route path="/payment-successful" element={<PaymentSuccess />} />
        <Route path="/otp" element={<Otp />} />

        <Route path="*" element={<Navigate to="/page-not-found" />} />
      </Routes>
    </Router>
  );
}
