import React, { useEffect, useState } from "react";
import DisplayGeneralData from "../components/drawer/DisplayGeneralData";
import DockMasterImage from "../assets/DockMaster.png";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import InvoiceTable from "./../components/tables/InvoiceTable";
import PaymentMethodPicker from "../components/PaymentMethodPicker";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CampaignIcon from "@mui/icons-material/Campaign";
import NumberInputBasic from "../components/input/NumberInput";
import { getAuthCookies } from "../context/CookieManager";
import {getCredentialsByClientId, getClientKeyByTenantId} from "./../api/CredentialsAPI"
import {
  getCurrentDateTime,
  roundTo,
  getDifferenceInDays,
  hexToRgba,
} from "../helpers/utils";
import { getLocationsAPI } from "./../api/DockMasterAPI";
import { getPaymentRequestAPI } from "../api/PaymentRequestAPI";
import { getCustomerInvoicesAPI } from "../api/DockMasterAPI";
import { createPaymentAPI } from "../api/PaymentAPI";
import { checkJWt } from "../api/Authentication";
import Snackbar from "@mui/material/Snackbar";
const DMPay = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { customerId, tenantId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [warning, setWarning] = useState({
    display: false,
    text: "",
    color: "",
  });
  const [loading, setLoading] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);
  const [error, setError] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [locations, setLocations] = useState([]);
  const [tenantInfo, setTenantInfo] = useState(null);
  const [customerData, setCustomerData] = useState({
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    merchantId: null,
    submerchantId: null,
  });
  const [submitForm, setSubmitForm] = useState({
    depositNotes: "",
    paymentMethod: "",
    convenienceFee: 0,
    surcharge: 0,
    email: "",
    amount: 0,
    depositAmount: 0,
    agreementCheckbox: false,
    invoices: [],
  });
  const [invoices, setInvoices] = useState(null);
  const isPayClickabled =
    !loading &&
    submitForm.amount + submitForm.depositAmount > 0 &&
    submitForm.paymentMethod !== "" &&
    submitForm.agreementCheckbox &&
    submitForm.email.trim() !== "";
    
  const fields = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
  ];
  useEffect(() => {
    if (invoices) {
      checkWarning(invoices, tenantInfo);
    }
  }, [invoices, tenantInfo]);
  const checkWarning = (invoices, tenantInfo) => {
    if (!Array.isArray(invoices) || invoices.length === 0) {
      console.error("Invoices array is not valid or is empty");
      return null; // Return null if invoices is not a valid array or is empty
    }
    const currentDate = new Date();
    // Ensure all invoices have a dueDate and find the oldest invoice due date
    let oldestInvoiceDate = invoices[0]?.dueDate
      ? new Date(invoices[0].dueDate)
      : currentDate;
    invoices.forEach((invoice) => {
      if (invoice?.dueDate) {
        const invoiceDate = new Date(invoice.dueDate);
        if (invoiceDate < oldestInvoiceDate) {
          oldestInvoiceDate = invoiceDate;
        }
      } else {
        console.warn("Invoice missing dueDate:", invoice);
      }
    });
    const differenceInDays = getDifferenceInDays(
      currentDate,
      oldestInvoiceDate,
    );
    const warnings = [];
    if (tenantInfo?.PastDueWarningOne?.enabled) {
      warnings.push(tenantInfo.PastDueWarningOne);
    }
    if (tenantInfo?.PastDueWarningTwo?.enabled) {
      warnings.push(tenantInfo.PastDueWarningTwo);
    }
    if (tenantInfo?.PastDueWarningThree?.enabled) {
      warnings.push(tenantInfo.PastDueWarningThree);
    }
    if (warnings.length === 0) {
      console.warn("No warnings are enabled in tenantInfo");
      return null; // Return null if no warnings are enabled
    }
    warnings.sort((a, b) => parseInt(a.days) - parseInt(b.days));
    const warning = getWarning(warnings, differenceInDays);
    setWarning({
      display: warning !== null,
      text: warning !== null ? warning.message : "",
      color: warning !== null ? warning.color : "",
    });
  };
  const getWarning = (warnings, daysPastDue) => {
    if (!Array.isArray(warnings) || warnings.length === 0) {
      console.error("Warnings array is not valid or is empty");
      return null; // Return null if warnings is not a valid array or is empty
    }
    if (daysPastDue < parseInt(warnings[0]?.days)) {
      return null; // No warning if days past due are less than the first warning's days
    }
    for (let i = 0; i < warnings.length; i++) {
      if (
        i + 1 < warnings.length &&
        daysPastDue < parseInt(warnings[i + 1]?.days)
      ) {
        return warnings[i];
      }
    }
    return warnings[warnings.length - 1]; // Return the last warning if days past due are greater than the highest threshold
  };
  const calculatedTotalAmount = () => {
    return roundTo(
      submitForm.depositAmount +
        submitForm.amount +
        submitForm.convenienceFee +
        submitForm.surcharge,
      2,
    );
  };
  useEffect(() => {
    checkTokenAvailibility();
  }, []);
  const checkTokenValidityAPI = async () => {
    try {
      const res = await checkJWt({
        token: getAuthCookies().accessToken,
        token_type: "Payment",
      });
      return res.is_valid;
    } catch (error) {
      return false;
    }
  };
  const checkTokenAvailibility = async () => {
    const paymentRequestId = queryParams.get("id");
    const token = getAuthCookies().accessToken;
    const valid = await checkTokenValidityAPI(token);
    if (customerId && tenantId) {
      if (valid) {
        getCustomerInvoices(customerId, tenantId);
      } else {
        navigate(`/otp?customer-id=${customerId}&tenant-id=${tenantId}`);
      }
    } else {
      if (valid) {
        getPaymentRequest(paymentRequestId);
      } else {
        navigate("/otp?id=" + paymentRequestId);
      }
    }
  };
  useEffect(() => {
    if (tenantInfo) {
      if (submitForm.paymentMethod === "DC") {
        let convenienceFee = tenantInfo.DMConvenienceFeeSurchargeEnabled
          ? tenantInfo.ConvenienceFlatFee
          : 0;
        setSubmitForm((prev) => {
          return {
            ...prev,
            convenienceFee: roundTo(convenienceFee, 2),
            surcharge: 0,
          };
        });
      }
      if (submitForm.paymentMethod === "CC") {
        let convenienceFee = tenantInfo.DMConvenienceFeeSurchargeEnabled
          ? tenantInfo.ConvenienceFlatFee
          : 0;
        let surchargeFee = tenantInfo.DMConvenienceFeeSurchargeEnabled
          ? calculateSurchage(
              tenantInfo,
              submitForm.amount + submitForm.depositAmount,
            )
          : 0;
        setSubmitForm((prev) => {
          return {
            ...prev,
            convenienceFee: roundTo(convenienceFee, 2),
            surcharge: roundTo(surchargeFee, 2),
          };
        });
      }
      if (submitForm.paymentMethod === "ACH") {
        let convenienceFee = tenantInfo.AchConvenienceFeeSurchargeEnabled
          ? tenantInfo.AchConvenienceFlatFee
          : 0;
        let surchargeFee = tenantInfo.AchConvenienceFeeSurchargeEnabled
          ? calculateSurchageACH(
              tenantInfo,
              submitForm.amount + submitForm.depositAmount,
            )
          : 0;
        setSubmitForm((prev) => {
          return {
            ...prev,
            convenienceFee: roundTo(convenienceFee, 2),
            surcharge: roundTo(surchargeFee, 2),
          };
        });
      }
    }
  }, [
    submitForm.depositAmount,
    submitForm.amount,
    submitForm.paymentMethod,
    tenantInfo,
  ]);
  const getCustomerInvoices = async (customerId, tenantId) => {
    try {
      const jsonResponse = await getCustomerInvoicesAPI(customerId, tenantId);
  
      setCustomerData({
        name: `${jsonResponse.open_invoices.firstName} ${jsonResponse.open_invoices.lastName}`,
        firstName: jsonResponse.open_invoices.firstName ?? "",
        lastName: jsonResponse.open_invoices.lastName ?? "",
        email: jsonResponse.customer_email ?? "",
        merchantId: jsonResponse.merchant_id ?? "",
        submerchantId: jsonResponse.submerchant_id ?? "",
      });
      setTenantInfo(jsonResponse.tenant_information);
      getLocations(jsonResponse.tenant_information.ClientID);
      // Filter locations based on the enabled property
      const enabledLocations = jsonResponse.tenant_information.PaymentLocationObject.filter(
        (location) => location.enabled
      );
  

    // Filter invoices based on enabled locations
    const filteredInvoices = jsonResponse?.open_invoices?.openARInvoices.filter(
      (invoice) => enabledLocations.some(location => location.Id === invoice.locationCode)
    ) ?? [];

    // Aggregate the amount based on the invoiceBalance of filtered invoices
    const totalInvoiceBalance = filteredInvoices.reduce(
      (total, invoice) => total + invoice.amount,
      0
    );

    setInvoices(filteredInvoices);
    setSubmitForm((prev) => {
      return {
        ...prev,
        amount: roundTo(totalInvoiceBalance, 2),
        paymentMethod:
          jsonResponse.tenant_information?.AcceptedCreditCards?.length > 0
            ? "CC"
            : jsonResponse.tenant_information?.AcceptAchPayments
            ? "ACH"
            : "",
      };
    });
    setDisplayLoader(false);
  } catch (error) {
    console.log("error", error);
    navigate("/page-not-found");
  }
};
  
  const getLocations = async (tenantId) => {
    try {
      const jsonResponse = await getLocationsAPI(tenantId);
      setLocations(jsonResponse ?? []);
    } catch (error) {
      console.log("error", error);
      navigate("/page-not-found");
    }
  };
  const calculateSurchage = (tenantInfo, amount) => {
    let calulatedSurcharge = (amount * tenantInfo.SurchargePercent) / 100;
    if (calulatedSurcharge > tenantInfo.SurchargeMaximumFee)
      return tenantInfo.SurchargeMaximumFee;
    else return calulatedSurcharge;
  };
  const calculateSurchageACH = (tenantInfo, amount) => {
    let calulatedSurcharge = (amount * tenantInfo.AchSurchargePercent) / 100;
    if (calulatedSurcharge > tenantInfo.AchSurchargeMaximumFee)
      return tenantInfo.AchSurchargeMaximumFee;
    else return calulatedSurcharge;
  };
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setSubmitForm({
        ...submitForm,
        [event.target.name]: event.target.checked,
      });
    } else {
      setSubmitForm({ ...submitForm, [event.target.name]: event.target.value });
    }
  };
  const handleChangeAmount = (amount, invoices) => {
    setSubmitForm({
      ...submitForm,
      amount: roundTo(amount, 2),
      invoices: invoices ?? [],
    });
  };
  const getPaymentRequest = async (paymentRequestId) => {
    try {
      const jsonResponse = await getPaymentRequestAPI(paymentRequestId);

      // Check if there are invoices in the jsonResponse
      if (jsonResponse.Invoices && jsonResponse.Invoices.length > 0) {
        // Get open customer invoices to ensure any closed invoices are removed from the payment request
        const openCustomerInvoices = await getCustomerInvoicesAPI(jsonResponse.customerId, jsonResponse.client_id);
        setTenantInfo(openCustomerInvoices.tenant_information);
        getLocations(openCustomerInvoices.tenant_information.ClientID);
    
        // Filter locations based on the enabled property
        const enabledLocations = openCustomerInvoices.tenant_information.PaymentLocationObject.filter(
          (location) => location.enabled
        );
    
        // Filter invoices based on enabled locations
        const filteredInvoices = openCustomerInvoices?.open_invoices?.openARInvoices.filter(
          (invoice) => enabledLocations.some(location => location.Id === invoice.locationCode)
        ) ?? [];
      
        // Remove invoices from jsonResponse that are not in filteredInvoices
        const updatedInvoices = jsonResponse.Invoices.filter(invoice => 
          filteredInvoices.some(filteredInvoice => filteredInvoice.id === invoice.id)
        );
    
        jsonResponse.Invoices = updatedInvoices;
      }
  
      setPaymentRequest(jsonResponse);
      setInvoices(jsonResponse.Invoices);
      setCustomerData({
        name: jsonResponse.firstname + " " + jsonResponse.lastname ?? "",
        firstName: jsonResponse.firstname ?? "",
        lastName: jsonResponse.lastname ?? "",
        email: jsonResponse.primaryemail ?? "",
        merchantId: jsonResponse.tenant_info.merchantId ?? "",
        submerchantId: jsonResponse.tenant_info.submerchantId ?? "",
      });
      setTenantInfo(jsonResponse.tenant_info);
      setSubmitForm((prev) => {
        return {
          ...prev,
          depositAmount: roundTo(jsonResponse.depositamount, 2),
          paymentMethod:
            jsonResponse.tenant_info?.AcceptedCreditCards?.length > 0
              ? "CC"
              : jsonResponse.tenant_info?.AcceptAchPayments
              ? "ACH"
              : "",
        };
      });
  
      getLocations(jsonResponse.client_id);
      setDisplayLoader(false);
    } catch (error) {
      console.log("error", error);
      navigate("/page-not-found");
    }
  };
  
  const handleCheckout = async () => {
    try {
      if (!paymentRequest && !customerId && !tenantId) {
        navigate("/page-not-found");
      }
      setLoading(true);
      setError(false);
      const totalAmount = calculatedTotalAmount();
      if (tenantInfo.PayOldestInvoiceFirst) {
        const sortedInvoices = invoices.sort(
          (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
        );
        const selectedInvoiceIds = new Set(
          submitForm.invoices.map((inv) => inv.id),
        );
        const firstUnselectedIndex = sortedInvoices.findIndex(
          (invoice) => !selectedInvoiceIds.has(invoice.id),
        );
        if (firstUnselectedIndex !== -1) {
          const isInvalidSelection = sortedInvoices
            .slice(firstUnselectedIndex)
            .some((invoice) => selectedInvoiceIds.has(invoice.id));
          if (isInvalidSelection) {
            setSnackbarMessage(
              "You must include all previous invoices before making a payment.",
            );
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }
        }
      }
      if (
        (submitForm.paymentMethod === "CC" ||
          submitForm.paymentMethod === "DC") &
        (totalAmount > tenantInfo.MaximumCcPaymentAmount)
      ) {
        setSnackbarMessage(
          "Amount exceeds the maximum limit of $" +
            tenantInfo.MaximumCcPaymentAmount,
        );
        setSnackbarOpen(true);
        setLoading(false);
        return;
      }
      const uuid = uuidv4();
      let body = {
        id: uuid,
        amount: totalAmount,
        currency: "USD",
        status: "UNPROCESSED",
        payment_method: submitForm.paymentMethod,
        customer_notes: submitForm.depositNotes,
        payment_state: "PENDING",
        payment_date: getCurrentDateTime(),
        external_transaction_id: "",
        firstname: customerData.firstName,
        lastname: customerData.lastName,
      };
      if (paymentRequest) {
        body["payment_request_id"] = paymentRequest.id;
        body["customer_id"] = parseInt(paymentRequest.customerId);
        body["client_id"] = paymentRequest.client_id;
        body["transaction_type"] = paymentRequest.deposittype;
      }
      if (customerId && tenantId) {
        body["payment_request_id"] = null;
        body["customer_id"] = customerId;
        body["client_id"] = tenantId;
        body["transaction_type"] = "SI";
      }
      const jsonResponse = await createPaymentAPI(body);
      const TenantClientKey = (await getClientKeyByTenantId(body["client_id"])).VpClientKey //get the clientKey relevant to the tenantId

      const paymentDetails = {
        paymentID: jsonResponse.id,
        amount: totalAmount,
        convenienceFee: submitForm.convenienceFee,
        surcharge: submitForm.surcharge,
        country_code: "US",
        shopper_reference: "00" + jsonResponse.customer_id,
        language: "en_US",
        shopper_email: customerData.email,
        merchantId: customerData.merchantId,
        submerchantId: customerData.submerchantId,
        invoices: submitForm.invoices,
        uuid: uuid,
        email: submitForm.email,
        payment_methods:
          submitForm.paymentMethod === "ACH"
            ? ["ACH"]
            : submitForm.paymentMethod === "CC" ||
              submitForm.paymentMethod === "DC"
            ? [...tenantInfo.AcceptedCreditCards]
            : [],
        RequireBillingAddress: tenantInfo?.RequireBillingAddress ?? false,
        PaymentNotificationEmails: tenantInfo?.PaymentNotificationEmails ?? "",
        fullName: `${customerData.firstName} ${customerData.lastName}`,
        depositAmount: submitForm.depositAmount,
        logo: tenantInfo?.PaymentFormImage ?? DockMasterImage,
        postBatch: tenantInfo.AutoPostPaymentBatch,
        putMiscDepositsOnAccount: tenantInfo.PutMiscDepositsOnAccount,
        applyCreditsToInvoice: tenantInfo.ApplyCreditsToInvoice,
        TenantClientKey: TenantClientKey,
      };

      if (paymentRequest) {
        paymentDetails["paymentRequest"] = paymentRequest
        paymentDetails["locationCode"] = paymentRequest.locationid;
        paymentDetails["tenantId"] = paymentRequest.client_id;
        paymentDetails["paymentLink"] = window.location.href;
        paymentDetails["transaction_type"] = paymentRequest.deposittype;
        paymentDetails["depositInvoiceId"] =
          paymentRequest?.additional_data?.customerContracts ||
          paymentRequest?.additional_data?.customerWorkOrders ||
          paymentRequest?.additional_data?.customerQuotes ||
          paymentRequest?.additional_data?.customerWaitList ||
          paymentRequest?.additional_data?.customerBoats ||
          paymentRequest?.additional_data?.customerSpecialOrders ||
          paymentRequest?.additional_data?.reservationId ||
          paymentRequest?.additional_data?.specificInvoice ||
          paymentRequest?.additional_data?.customerInvoices ||
          "";
        const depositTenantArray = tenantInfo.DepositTypesObject.filter(
          (data) => data.id === paymentRequest.deposittype,
        );
        paymentDetails["depositNotificationUsers"] =
          depositTenantArray.length > 0
            ? depositTenantArray[0]?.emails ?? []
            : [];
      }
      if (customerId && tenantId) {
        paymentDetails["transaction_type"] = "SI";
        paymentDetails["locationCode"] = tenantInfo.DefaultPayLoc ?? 1;
        paymentDetails["tenantId"] = tenantId;
        paymentDetails["paymentLink"] = null;
        paymentDetails["depositNotificationUsers"] = [];
      }
      if(!paymentDetails["locationCode"] || paymentDetails["locationCode"] =='all'){
        paymentDetails["locationCode"] = tenantInfo.DefaultPayLoc ?? 1;
      }
      navigate("/checkout", { state: { paymentDetails, tenantInfo } });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const handlePaymentChange = (paymentMethod) => {
    setSubmitForm({ ...submitForm, paymentMethod });
  };


  return (
    <>
      <Grid container sx={{ height: "100vh" }}>
        {displayLoader ? (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            sx={{ width: "100%" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  py: { xs: 1.5, sm: 0 },
                  height: "100%",
                  backgroundColor: "#F2F4F7",
                }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    width: { xs: "80%", sm: "60%" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "60%", sm: "100%" },
                      mx: { xs: "auto", sm: 0 },
                      mb: { xs: 2, sm: 0 },
                    }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      style={{ width: "100%", marginBottom: "20px" }}
                      src={tenantInfo?.PaymentFormImage ?? DockMasterImage}
                      alt="Dock Master"
                    />
                  </Box>
                  <DisplayGeneralData rowData={customerData} fields={fields} />
                  {warning.display && (
                    <Box
                      sx={{
                        my: 5,
                        border: 2,
                        borderRadius: 1,
                        backgroundColor: hexToRgba(warning.color, 0.1),
                        py: 3,
                        px: 2,
                        color: warning.color,
                        borderColor: warning.color,
                      }}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <CampaignIcon sx={{ px: 2, color: warning.color }} />
                      {warning.text}
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  overflowY: { xs: "", sm: "scroll" },
                  height: { xs: "100%", sm: "100vh" },
                }}
              >
                <Box
                  sx={{
                    pt: invoices && invoices.length > 0 ? 5 : 0,
                    height: "100%",
                    width: "80%",
                    marginX: "auto",
                  }}
                >
                  {invoices && invoices.length > 0 && (
                    <InvoiceTable
                      handleChangeAmount={handleChangeAmount}
                      data={invoices}
                      locations={locations}
                    />
                  )}
                  <Box
                    sx={{
                      mt: 5,
                      marginX: "auto",
                    }}
                  >
                    {!tenantInfo.NoMiscDeposits &&
                      (invoices == null || invoices.length === 0) && (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "400",
                            fontFamily: "Hanken Grotesk",
                            color: "red",
                            textAlign: "center",
                            mb: 2,
                          }}
                        >
                          No invoices selected
                        </Typography>
                    )}

                    {!tenantInfo.NoMiscDeposits && (
                        <>
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "400",
                                fontFamily: "Hanken Grotesk",
                                color: "#101828",
                              }}
                            >
                              Deposit
                            </Typography>
                            <NumberInputBasic
                              value={submitForm.depositAmount}
                              placeholder="0.00"
                              onInputChange={(e) =>
                                setSubmitForm((prev) => ({
                                  ...prev,
                                  depositAmount: Number(
                                    e.target.value.replace(/[^0-9.]/g, ""),
                                  ),
                                }))
                              }
                              fullWidth
                              sx={{
                                backgroundColor: invoices && invoices.length === 0 ? "#F0F0F0" : "inherit" // Grey out the field if disabled
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              pt: 1.5,
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "400",
                                fontFamily: "Hanken Grotesk",
                                color: "#101828",
                              }}
                            >
                              Deposit Notes
                            </Typography>
                            <TextField
                              value={submitForm.depositNotes}
                              onChange={handleChange}
                              name="depositNotes"
                              placeholder="Enter any deposit notes"
                              sx={{
                                background: "#F0F2F5",
                              }}
                              size="small"
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </>
                      )}
                  </Box>
                  <Box
                    sx={{
                      mt: 5,
                    }}
                  >
                    <PaymentMethodPicker
                      handlePaymentChange={handlePaymentChange}
                      AcceptAchPayments={tenantInfo.AcceptAchPayments}
                      cards={tenantInfo.AcceptedCreditCards}
                      selectedMethod={submitForm.paymentMethod}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                      mt: 5,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Hanken Grotesk",
                        color: "#344054",
                      }}
                    >
                      Convenience Fee:
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        fontFamily: "Hanken Grotesk",
                        color: "#101828",
                        fontWeight: "bold",
                      }}
                    >
                      ${submitForm.convenienceFee.toFixed(2)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Hanken Grotesk",
                        color: "#344054",
                      }}
                    >
                      Surcharge:
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Hanken Grotesk",
                        color: "#101828",
                      }}
                    >
                      ${submitForm.surcharge.toFixed(2)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="span"
                      sx={{
                        fontWeight: "700",
                        fontFamily: "Hanken Grotesk",
                        color: "#344054",
                      }}
                    >
                      Total:
                    </Typography>
                    <Typography
                      variant="h5"
                      component="span"
                      sx={{
                        fontWeight: "700",
                        fontFamily: "Hanken Grotesk",
                        color: "#101828",
                      }}
                    >
                      ${calculatedTotalAmount()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Hanken Grotesk",
                        color: "#101828",
                      }}
                    >
                      Email
                    </Typography>
                    <TextField
                      name="email"
                      value={submitForm.email}
                      onChange={handleChange}
                      sx={{
                        background: "#F0F2F5",
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                      mt: 2.5,
                    }}
                  >
                    <Checkbox
                      checked={submitForm.agreementCheckbox}
                      name="agreementCheckbox"
                      onChange={handleChange}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "500",
                        fontFamily: "Hanken Grotesk",
                        color: "#101828",
                        textAlign: "right",
                      }}
                    >
                      By checking this box, I understand that I am being charged
                      and
                      <br /> agree to pay a convenience fee of $
                      {submitForm.convenienceFee.toFixed(2)} and surcharge of{" "}
                      {submitForm.surcharge.toFixed(2)} USD.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      py: 2,
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {error && "Something went wrong please contact support"}
                  </Typography>
                  <PrimaryButton
                    sx={{
                      width: "100%",
                      mb: 2,
                    }}
                    disabled={!isPayClickabled}
                    type="submit"
                    onClick={handleCheckout}
                  >
                    {loading ? <CircularProgress size={24} /> : "PAY"}
                  </PrimaryButton>
                </Box>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default DMPay;
